import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'
import dayjs from 'dayjs'

require('dayjs/locale/es')

export default function useCalendarEventHandler(props, clearForm, emit) {
  // ------------------------------------------------
  // eventLocal
  // ------------------------------------------------
  props.event.value.start = dayjs(new Date()).locale('es').toString()
  const eventLocal = ref(JSON.parse(JSON.stringify(props.event.value)))
  const resetEventLocal = () => {
    eventLocal.value = JSON.parse(JSON.stringify(props.event.value))
  }
  watch(props.event, () => {
    resetEventLocal()
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ------------------------------------------------
  watch(props.isEventHandlerSidebarActive, val => {
    // ? Don't reset event till transition is finished
    if (!val) {
      setTimeout(() => {
        clearForm.value()
      }, 350)
    }
  })
  // ------------------------------------------------
  // calendarOptions
  // ------------------------------------------------
  const calendarOptions = computed(() => store.state.calendar.calendarOptions)
  const calendarTypes = ['Servicio', ' Actividad', 'Estado']
  const customerList = computed(() => store.state.customers.customer_list)
  const serviceList = computed(() => store.state.services.service_list)

  const onSubmit = () => {

    const eventData = JSON.parse(JSON.stringify(eventLocal))
    console.log("onSubmit ", eventData)

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.event.value.id) emit('update-event', eventData.value)
    else emit('add-event', eventData.value)

    // Close sidebar
    emit('update:is-event-handler-sidebar-active', false)
  }

  const changeService = () => {
    const eventData = eventLocal.value
    eventData.start = dayjs(eventData.start).locale('es').toString()
    console.log("eventData.start ", eventData.start)
    if (eventData.extendedProps.service.length > 0) {
      const countHours = eventData.extendedProps.service.reduce((total, service) => total += service.serviceDuration, 0)
      const endTimeCalc = dayjs(eventData.start).add(countHours, 'hour')
      eventData.end = endTimeCalc.locale('es').toString()
    } else {
      eventData.end = null
    }
    console.log("eventData.end ", eventData.end)
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // ------------------------------------------------
  // guestOptions
  // ------------------------------------------------

  /* eslint-disable global-require */
  /*
  const guestsOptions = [
    { avatar: require('@/assets/images/avatars/1-small.png'), name: 'Jane Foster' },
    { avatar: require('@/assets/images/avatars/3-small.png'), name: 'Donna Frank' },
    { avatar: require('@/assets/images/avatars/5-small.png'), name: 'Gabrielle Robertson' },
    { avatar: require('@/assets/images/avatars/7-small.png'), name: 'Lori Spears' },
    { avatar: require('@/assets/images/avatars/9-small.png'), name: 'Sandy Vega' },
    { avatar: require('@/assets/images/avatars/11-small.png'), name: 'Cheryl May' },
  ]
  */
  const guestsOptions = computed(() => store.state.resources.resource_list.map(r => ({
    _id: r._id,
    resourceName: r.resourceName,
    eventBackgroundColor: r.eventBackgroundColor,
    photoUrl: r.photoUrl,
  })))
  /* eslint-enable global-require */

  return {
    eventLocal,
    resetEventLocal,
    calendarOptions,
    calendarTypes,
    customerList,
    serviceList,
    changeService,

    // UI
    guestsOptions,
    onSubmit,
  }
}
