<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ eventLocal.id ? 'Update': 'Add' }} Event
          </h5>
          <div>
            <feather-icon
              v-if="eventLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-event'); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- Type -->
            <validation-provider
              #default="validationContext"
              name="Type"
              rules="required"
            >

              <b-form-group
                label="Tipo"
                label-for="calendar"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventLocal.extendedProps.eventType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="calendarTypes"
                  input-id="type"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <div v-if="eventLocal.extendedProps.eventType">
              <!-- Customer -->
              <validation-provider
                #default="validationContext"
                name="Cliente"
                rules="required"
              >

                <b-form-group
                  label="Cliente"
                  label-for="calendar"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="eventLocal.extendedProps.customer"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="customerList"
                    label="customerName"
                    :reduce="customer => customer.customerName"
                    input-id="calendar"
                  >

                    <template #option="{ customerName }">
                      <span> {{ customerName }}</span>
                    </template>

                    <template #selected-option="{ customerName }">
                      <span> {{ customerName }}</span>
                    </template>
                  </v-select>

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <div class="text-right">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    size="sm"
                  >
                    <feather-icon icon="UserPlusIcon" />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                  >
                    <feather-icon icon="Edit2Icon" />
                  </b-button>
                </div>
              </validation-provider>

              <!-- Services -->
              <validation-provider
                #default="validationContext"
                name="Servicio"
                rules="required"
              >

                <b-form-group
                  label="Servicio"
                  label-for="calendar"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="eventLocal.extendedProps.service"
                    multiple
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="serviceList"
                    label="serviceName"
                    input-id="calendar"
                    @input="changeService"
                  >

                    <template #option="{ serviceName, serviceDuration }">
                      <feather-icon
                        icon="BoxIcon"
                      />
                      {{ serviceName }}  ({{ serviceDuration }} h)
                    </template>

                    <template #selected-option="{ serviceName, serviceDuration }">
                      <feather-icon
                        icon="BoxIcon"
                      />
                      {{ serviceName }}  ({{ serviceDuration }} h)
                    </template>
                  </v-select>

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- Title
              <validation-provider
                v-if="eventLocal.extendedProps.eventType"
                #default="validationContext"
                name="Title"
                rules="required"
              >
                <b-form-group
                  label="Title"
                  label-for="event-title"
                >
                  <b-form-input
                    id="event-title"
                    v-model="eventLocal.title"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Event Title"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider> -->

              <!-- Calendar
              <validation-provider
                #default="validationContext"
                name="Calendar"
                rules="required"
              >

                <b-form-group
                  label="Calendar"
                  label-for="calendar"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="eventLocal.extendedProps.calendar"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="calendarOptions"
                    label="label"
                    :reduce="calendar => calendar.label"
                    input-id="calendar"
                  >

                    <template #option="{ color, label }">
                      <div
                        class="rounded-circle d-inline-block mr-50"
                        :class="`bg-${color}`"
                        style="height:10px;width:10px"
                      />
                      <span> {{ label }}</span>
                    </template>

                    <template #selected-option="{ color, label }">
                      <div
                        class="rounded-circle d-inline-block mr-50"
                        :class="`bg-${color}`"
                        style="height:10px;width:10px"
                      />
                      <span> {{ label }}</span>
                    </template>
                  </v-select>

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>-->

              <template v-if="!eventLocal.allDay">
                <!-- Start Date -->
                <validation-provider
                  #default="validationContext"
                  name="Fecha inicio"
                  rules="required"
                >

                  <b-form-group
                    label="Fecha inicio"
                    label-for="start-date"
                    :state="getValidationState(validationContext)"
                  >
                    <flat-pickr
                      v-model="eventLocal.start"
                      time_24hr
                      class="form-control"
                      time-format="H:i"
                      :config="{ enableTime: true, dateFormat: 'D d M H:i'}"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- End Date -->
                <validation-provider
                  #default="validationContext"
                  name="Fecha fin"
                  rules="required"
                >

                  <b-form-group
                    label="Fecha fin"
                    label-for="end-date"
                    :state="getValidationState(validationContext)"
                  >
                    <flat-pickr
                      v-model="eventLocal.end"
                      time_24hr
                      class="form-control"
                      time-format="H:i"
                      :config="{ enableTime: true, dateFormat: 'D d M H:i'}"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </template>

              <!-- All Day -->
              <b-form-group>
                <b-form-checkbox
                  v-model="eventLocal.allDay"
                  name="check-button"
                  switch
                  inline
                >
                  Todo el día
                </b-form-checkbox>
              </b-form-group>

              <!-- Guests -->
              <validation-provider
                #default="validationContext"
                name="Seleccionar recurso"
                rules="required"
              >
                <b-form-group
                  label="Seleccionar recurso"
                  label-for="add-guests"
                >
                  <v-select
                    v-model="eventLocal.extendedProps.guests"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :close-on-select="false"
                    :options="guestsOptions"
                    label="resourceName"
                    input-id="add-guests"
                  >

                    <template #option="{ photoUrl, resourceName }">
                      <b-avatar
                        size="sm"
                        :src="photoUrl"
                      />
                      <span class="ml-50 align-middle"> {{ resourceName }}</span>
                    </template>

                    <template #selected-option="{ photoUrl, resourceName }">
                      <b-avatar
                        size="sm"
                        class="border border-white"
                        :src="photoUrl"
                      />
                      <span class="ml-50 align-middle"> {{ resourceName }}</span>
                    </template>
                  </v-select>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- Location -->
              <b-form-group
                label="Precio"
                label-for="event-price"
              >
                <b-form-input
                  id="event-price"
                  v-model="eventLocal.extendedProps.totalAmount"
                  trim
                  placeholder="Precio Final"
                />
              </b-form-group>

              <!-- Textarea -->
              <b-form-group
                label="Comentarios"
                label-for="event-comments"
              >
                <b-form-textarea
                  id="event-comments"
                  v-model="eventLocal.extendedProps.eventDescription"
                />
              </b-form-group>
              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  {{ eventLocal.id ? 'Update' : 'Add ' }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                >
                  Reset
                </b-button>
              </div>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BAvatar, BFormTextarea, BButton, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from "flatpickr/dist/l10n/ru.js"
flatpickr.localize(Spanish) // default locale is now Russian
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import useCalendarEventHandler from './useCalendarEventHandler'

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,
      calendarTypes,
      customerList,
      serviceList,
      changeService,

      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm
    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      calendarTypes,
      customerList,
      serviceList,
      changeService,

      onSubmit,
      guestsOptions,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
